<template>
  <footer>
    <p>Not associated with or approved by Mojang Studios</p>
  </footer>
</template>

<script>
export default {
  name: "FooterBar",
};
</script>

<style scoped>
footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #181818;
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
}

</style>
